import React from 'react';
import { Modal } from 'react-bootstrap';
import { User } from '../../../../../store/unified_user_store/types';

interface Props {
  user: User;
  token: string;
  show: boolean;
  handleHide: () => void;
}

const UserLoginModal = ({ user, token, show, handleHide }: Props) => {
  const userName = user.name;
  const userTenantName = user.tenant.name;

  const domain =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'metwatch.nz'
      : 'metwatch.io';

  const url = `https://${userTenantName}.${domain}/?token=${token}`;

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          Login as {userName} ({userTenantName})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='text-break'>
          <a href={url}>{url}</a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserLoginModal;


import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { devConsoleLog } from '../../../../../logging';
import {
  UnifiedUserContext,
  UserContext
} from '../../../../../store/StoreContexts';
import { User } from '../../../../../store/unified_user_store/types';
import { errorToast, successToast } from '../../../../utils/ToastContainer';

type FormValues = {
  name: string;
  phone: string;
  forecast_provider: string;
  station_id: string;
  active: boolean;
};

interface Props {
  user: User;
}

const UserEditForm = ({ user }: Props) => {
  const userStore = useContext(UserContext);
  const unifiedUserStore = useContext(UnifiedUserContext);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      setLoading(true);
      try {
        const url = `${process.env.REACT_APP_AUTH_API_URL}api/admin/unified/user/${user.id}`;
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer}`,
          tenant: user.tenant.name
        };
        const response = await fetch(url, {
          method: 'PUT',
          headers,
          body: JSON.stringify({ ...values })
        });
        if (!response.ok) {
          if (response.status === 401) {
            await userStore.refresh();
          } else {
            errorToast('Failed to update user.');
          }
        } else {
          const user = (await response.json()) as User;
          unifiedUserStore.updateUser(user);
          successToast('User updated successfully.');
        }
      } catch (error) {
        devConsoleLog(error);
      } finally {
        setLoading(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [unifiedUserStore, user.id, user.tenant.name, userStore]
  );

  return (
    <Formik
      initialValues={
        {
          name: user.name,
          phone: user.phone,
          forecast_provider: user.forecast_provider,
          station_id: user.station_id,
          active: user.active
        } as FormValues
      }
      onSubmit={handleSubmit}
    >
      {(fProps) => {
        return (
          <Form onSubmit={fProps.handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                value={fProps.values.name}
                onChange={fProps.handleChange}
                onBlur={fProps.handleBlur}
                isInvalid={fProps.touched.name && !!fProps.errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {fProps.errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                name='phone'
                value={fProps.values.phone}
                onChange={fProps.handleChange}
                onBlur={fProps.handleBlur}
                isInvalid={fProps.touched.phone && !!fProps.errors.phone}
              />
              <Form.Control.Feedback type='invalid'>
                {fProps.errors.phone}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Forecast Provider</Form.Label>
              <Form.Control
                type='text'
                name='forecast_provider'
                value={fProps.values.forecast_provider}
                onChange={fProps.handleChange}
                onBlur={fProps.handleBlur}
                isInvalid={
                  fProps.touched.forecast_provider &&
                  !!fProps.errors.forecast_provider
                }
              />
              <Form.Control.Feedback type='invalid'>
                {fProps.errors.forecast_provider}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Station ID</Form.Label>
              <Form.Control
                type='text'
                name='station_id'
                value={fProps.values.station_id}
                onChange={fProps.handleChange}
                onBlur={fProps.handleBlur}
                isInvalid={
                  fProps.touched.station_id && !!fProps.errors.station_id
                }
              />
              <Form.Control.Feedback type='invalid'>
                {fProps.errors.station_id}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Check
                type='checkbox'
                label='Active'
                name='active'
                checked={fProps.values.active}
                onChange={fProps.handleChange}
              />
            </Form.Group>
            <Button type='submit' disabled={fProps.isSubmitting || loading}>
              {loading ? (
                <Spinner className='mx-3' animation={'border'} size='sm' />
              ) : (
                'Submit'
              )}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(UserEditForm);


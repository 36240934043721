import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { UserContext } from '../../../../../store/StoreContexts';
import { User } from '../../../../../store/unified_user_store/types';
import { errorToast } from '../../../../utils/ToastContainer';
import { useLoginAsUser } from './useLoginAsUser';
import UserLoginModal from './UserLoginModal';

interface Props {
  user: User;
}

const UserLoginButton = ({ user }: Props) => {
  const userStore = useContext(UserContext);
  const { loading, loginAsUser } = useLoginAsUser(userStore);
  const [show, setShow] = useState(false);
  const [token, setToken] = useState('');

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <Button
        variant='secondary'
        size='sm'
        disabled={loading}
        onClick={async () => {
          const data = await loginAsUser(user);
          const token = data?.jwt_token;
          console.log(token);
          if (token) {
            handleShow();
            setToken(token);
          } else {
            errorToast('Failed to login as user.');
          }
        }}
      >
        {loading ? (
          <Spinner className='mx-4' animation={'border'} size='sm' />
        ) : (
          'Industry Login'
        )}
      </Button>
      <UserLoginModal
        user={user}
        token={token}
        show={show}
        handleHide={handleHide}
      />
    </>
  );
};

export default observer(UserLoginButton);


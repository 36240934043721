import React from 'react';
import { InputGroup } from 'react-bootstrap';
import {
  filterableColumns,
  FilterableColumnsKey,
  sortableColumns,
  SortableColumnsKey
} from '../utils';

interface Props {
  relation: string | undefined;
  column: string;
  relationsColumns: typeof sortableColumns | typeof filterableColumns;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ColumnSelect = ({
  relation,
  column,
  relationsColumns,
  placeholder,
  onChange
}: Props) => {
  return (
    <InputGroup className='w-auto'>
      <InputGroup.Prepend>
        <InputGroup.Text>{relation || '-'}</InputGroup.Text>
      </InputGroup.Prepend>
      <div>
        <select
          className='form-control h-100'
          value={column}
          onChange={(e) => {
            onChange(e);
          }}
          style={{ width: 'auto', display: 'inline-block' }}
        >
          <option value='' selected disabled hidden>
            {placeholder}
          </option>
          {Object.keys(relationsColumns).map((relation: string) => {
            const columns = Object.keys(
              relationsColumns[
                relation as SortableColumnsKey | FilterableColumnsKey
              ]
            );
            return (
              <optgroup key={relation} label={relation}>
                {columns.map((column: string) => (
                  <option
                    key={`${relation}.${column}`}
                    value={`${relation}.${column}`}
                  >
                    {column}
                  </option>
                ))}
              </optgroup>
            );
          })}
        </select>
      </div>
    </InputGroup>
  );
};

export default ColumnSelect;


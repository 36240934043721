import React from 'react';
import {
  filterableColumns,
  FilterableColumnsKey,
  FilterableColumnTypes,
  filterOperators
} from '../../utils';
import ColumnSelect from '../ColumnSelect';
import ValueInput from './ValueInput/ValueInput';

interface Props {
  relationState: [
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>
  ];
  columnState: [string, React.Dispatch<React.SetStateAction<string>>];
  operatorState: [string, React.Dispatch<React.SetStateAction<string>>];
  valueState: [string, React.Dispatch<React.SetStateAction<string>>];
}

const FilterForm = ({
  relationState,
  columnState,
  operatorState,
  valueState
}: Props) => {
  const [relation, setRelation] = relationState;
  const [column, setColumn] = columnState;
  const [operator, setOperator] = operatorState;
  const [value, setValue] = valueState;

  const filterableColumnsForRelation = relation
    ? (filterableColumns[relation as FilterableColumnsKey] as {
        [key: string]: { label: string; type: string };
      })
    : undefined;

  const columnName = column === 'user' ? column : column.split('.')[1];
  const filterableColumn = filterableColumnsForRelation
    ? filterableColumnsForRelation[columnName]
    : undefined;
  const filterableColumnType = filterableColumn?.type as FilterableColumnTypes;

  const filterableTypeToInputType = (filterableType: FilterableColumnTypes) => {
    switch (filterableType) {
      case 'string':
        return 'text';
      case 'number':
        return 'number';
      case 'boolean':
        return 'checkbox';
      case 'date':
        return 'date';
      default:
        return 'text';
    }
  };

  const operators = filterableColumn
    ? filterOperators.filter((op) => {
        return op.types.includes(filterableColumnType);
      })
    : filterOperators;

  return (
    <>
      <ColumnSelect
        relation={relation}
        column={column}
        relationsColumns={filterableColumns}
        placeholder='Select column to filter'
        onChange={(e) => {
          const selectedOption = e.target.selectedOptions[0];
          const optgroup = selectedOption.parentElement as HTMLOptGroupElement;
          const selectedValue = selectedOption.value;
          const groupLabel =
            optgroup.tagName === 'OPTGROUP' ? optgroup.label : null;
          setRelation(groupLabel || '');
          setColumn(selectedValue);
        }}
      />
      <div>
        <select
          className='form-control h-100'
          value={operator}
          onChange={(e) => {
            setOperator(e.target.value);
          }}
          style={{ width: 'auto', display: 'inline-block' }}
        >
          <option value='' selected disabled hidden>
            Select operator
          </option>
          {operators.map((op) => (
            <option key={op.operator} value={op.operator}>
              {op.label}
            </option>
          ))}
        </select>
      </div>
      <div className='mr-3'>
        <ValueInput
          type={filterableTypeToInputType(filterableColumnType || 'string')}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
        />
      </div>
    </>
  );
};

export default FilterForm;


import React from 'react';
import { Modal } from 'react-bootstrap';
import { User } from '../../../../../store/unified_user_store/types';
import UserEditForm from './UserEditForm';

interface Props {
  user: User;
  show: boolean;
  handleHide: () => void;
}

const UserEditModal = ({ user, show, handleHide }: Props) => {
  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          Edit User <b>{user.name}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserEditForm user={user} />
      </Modal.Body>
    </Modal>
  );
};

export default UserEditModal;


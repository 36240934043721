import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Filter } from '../../types';
import FilterForm from './FilterForm';

interface Props {
  onFilterAdd: (filters: Filter) => void;
}

const AddFilter = ({ onFilterAdd }: Props) => {
  const relationState = useState<string | undefined>();
  const columnState = useState('');
  const operatorState = useState('eq');
  const valueState = useState('');

  const [relation, setRelation] = relationState;
  const [column, setColumn] = columnState;
  const [operator, setOperator] = operatorState;
  const [value, setValue] = valueState;

  const reset = () => {
    setRelation(undefined);
    setColumn('');
    setOperator('eq');
    setValue('');
  };

  return (
    <>
      <FilterForm
        relationState={relationState}
        columnState={columnState}
        operatorState={operatorState}
        valueState={valueState}
      />
      <div>
        <Button
          disabled={column === '' || operator === '' || value === ''}
          onClick={() => {
            onFilterAdd({
              relation: relation || '',
              column,
              operator,
              value
            });
            reset();
          }}
        >
          Add
        </Button>
      </div>
    </>
  );
};

export default AddFilter;


import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { User } from '../../../../../store/unified_user_store/types';
import UserEditModal from './UserEditModal';

interface Props {
  user: User;
}

const UserEditButton = ({ user }: Props) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <Button className='primary' size='sm' onClick={handleShow}>
        <FontAwesomeIcon className='mr-1' icon={faEdit} />
        Edit User
      </Button>
      <UserEditModal user={user} show={show} handleHide={handleHide} />
    </>
  );
};

export default UserEditButton;


import { observer } from 'mobx-react-lite';
import React from 'react';
import { Container } from 'react-bootstrap';
import UsersTable from './UsersTable/UsersTable';

const UnifiedUserManager = () => {
  return (
    <Container>
      <div className='page-title pb-0'>Unified User Manager</div>
      <br></br>
      <UsersTable />
    </Container>
  );
};

export default observer(UnifiedUserManager);


import React from 'react';
import { Button } from 'react-bootstrap';

interface Props {
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  onPageSizeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const PaginationControls = ({
  pageIndex,
  pageSize,
  pageCount,
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  previousPage,
  onPageSizeChange
}: Props) => {
  return (
    <>
      <div>
        <select
          className='form-control'
          value={pageSize}
          onChange={(e) => onPageSizeChange(e)}
          style={{ width: 'auto', display: 'inline-block' }}
        >
          {[1, 5, 10, 20, 50].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
      <nav>
        <ul className='pagination mb-0'>
          <li className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
            <Button
              className='page-link'
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              &laquo; First
            </Button>
          </li>
          <li className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
            <Button
              className='page-link'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
          </li>
          <li className='page-item disabled'>
            <span className='page-link'>
              Page {pageIndex + 1} of {pageCount}
            </span>
          </li>
          <li className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
            <Button
              className='page-link'
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </Button>
          </li>
          <li className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
            <Button
              className='page-link'
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Last &raquo;
            </Button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default PaginationControls;


import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Filter } from '../../types';
import FilterForm from './FilterForm';

interface Props {
  initialRelation?: string;
  initialColumn?: string;
  initialOperator?: string;
  initialValue?: string;
  filterIndex: number;
  onFilterEdit: (filter: Filter, index: number) => void;
  onFilterRemove: (index: number) => void;
}

const EditFilter = ({
  initialRelation,
  initialColumn,
  initialOperator,
  initialValue,
  filterIndex,
  onFilterEdit,
  onFilterRemove
}: Props) => {
  const relationState = useState<string | undefined>(initialRelation);
  const columnState = useState(initialColumn || '');
  const operatorState = useState(initialOperator || 'eq');
  const valueState = useState(initialValue || '');

  const [relation] = relationState;
  const [column] = columnState;
  const [operator] = operatorState;
  const [value] = valueState;

  // Determine whether the inputs have changed from initial.
  const hasChanged =
    initialRelation !== relation ||
    initialColumn !== column ||
    initialOperator !== operator ||
    initialValue !== value;

  return (
    <>
      <FilterForm
        relationState={relationState}
        columnState={columnState}
        operatorState={operatorState}
        valueState={valueState}
      />
      <div className='mr-2'>
        <Button
          variant='secondary'
          disabled={!hasChanged}
          onClick={() => {
            onFilterEdit(
              {
                relation: relation || '',
                column,
                operator,
                value
              },
              filterIndex
            );
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </div>
      <div>
        <Button
          variant='ghost'
          onClick={() => {
            onFilterRemove(filterIndex);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    </>
  );
};

export default EditFilter;


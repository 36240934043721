import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import Route from './components/navigation/Route';
import AccountDetails from './components/tools/account/AccountDetails';
import BomStationsPage from './components/tools/bom_stations/BomStationsPage';
import HaloStationsPage from './components/tools/halo_stations/HaloStationsPage';
import HarvestStationsPage from './components/tools/harvest_stations/HarvestStationsPage';
import HilltopStationsPage from './components/tools/hilltop_stations/HilltopStationsPage';
import ReportsManager from './components/tools/mdr/ReportsManager';
import MetrisStationsPage from './components/tools/metris_stations/MetrisStationsPage';
import MetserviceStationPage from './components/tools/metservice_stations/MetserviceStationsPage';
import CountriesRegionsManager from './components/tools/regions_manager/CountriesRegionsManager';
import WeatherStationManager from './components/tools/stations/WeatherStationManager';
import UnifiedUserManager from './components/tools/unified_user_manager/UnifiedUserManager';
import AccessArrayManager from './components/tools/user_access_arrays/AccessArrayManager';
import UserManager from './components/tools/users/UserManager';
import WinetStationsPage from './components/tools/winet_stations/WinetStationsPage';
import apm, { setupApmUser } from './rum';
import { UserContext } from './store/StoreContexts';

function App() {
  const userStore = useContext(UserContext);
  // Associate user ID with logs.
  useEffect(() => setupApmUser(apm, userStore.user?.userDetails.client_id), [
    userStore.user?.userDetails
  ]);

  return (
    <>
      <ToastContainer />
      <Router>
        <Route
          exact
          path='/'
          component={<WeatherStationManager />}
          requiresAuth
        ></Route>
        <Route
          path='/countries-regions-manager'
          component={<CountriesRegionsManager />}
          requiresAuth
        />
        <Route
          path='/harvest-stations'
          component={<HarvestStationsPage />}
          requiresAuth
        />
        <Route
          path='/hilltop-stations'
          component={<HilltopStationsPage />}
          requiresAuth
        />
        <Route
          path='/halo-stations'
          component={<HaloStationsPage />}
          requiresAuth
        />
        <Route
          path='/metservice-stations'
          component={<MetserviceStationPage />}
          requiresAuth
        />
        <Route
          path='/winet-stations'
          component={<WinetStationsPage />}
          requiresAuth
        />
        <Route
          path='/bom-stations'
          component={<BomStationsPage />}
          requiresAuth
        />
        <Route
          path='/metris-stations'
          component={<MetrisStationsPage />}
          requiresAuth
        />
        <Route path='/reports' component={<ReportsManager />} requiresAuth />
        <Route path='/user-manager' component={<UserManager />} requiresAuth />
        <Route
          path='/unified-user-manager'
          component={<UnifiedUserManager />}
          requiresAuth
        />
        <Route path='/account' component={<AccountDetails />} requiresAuth />
        <Route
          path='/access-array-manager'
          component={<AccessArrayManager />}
          requiresAuth
        />
      </Router>
    </>
  );
}

export default observer(App);


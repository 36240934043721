import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Sort } from '../types';
import { sortableColumns } from '../utils';
import ColumnSelect from './ColumnSelect';

interface Props {
  sortColumns: Sort[];
  onSortColumnAdd: (sortColumn: Sort) => void;
  onSortColumnRemove: (index: number) => void;
}

const SortingControls = ({
  sortColumns,
  onSortColumnAdd,
  onSortColumnRemove
}: Props) => {
  const [column, setColumn] = useState('');
  const [direction, setDirection] = useState('asc');
  const [relation, setRelation] = useState<string | undefined>();

  return (
    <div>
      <div className='d-flex mb-2'>
        <ColumnSelect
          relation={relation}
          column={column}
          relationsColumns={sortableColumns}
          placeholder='Select column to sort'
          onChange={(e) => {
            const selectedOption = e.target.selectedOptions[0];
            const optgroup = selectedOption.parentElement as HTMLOptGroupElement;
            const selectedValue = selectedOption.value;
            const groupLabel =
              optgroup.tagName === 'OPTGROUP' ? optgroup.label : null;
            setRelation(groupLabel || '');
            setColumn(selectedValue);
          }}
        />
        <div className='mr-3'>
          <ButtonGroup toggle>
            <Button
              variant='outline-secondary'
              active={direction === 'asc'}
              onClick={() => setDirection('asc')}
            >
              Asc
            </Button>
            <Button
              variant='outline-secondary'
              active={direction === 'desc'}
              onClick={() => setDirection('desc')}
            >
              Desc
            </Button>
          </ButtonGroup>
        </div>
        <div>
          <Button
            disabled={column === ''}
            onClick={() => {
              // Strip 'user.' if exists in sortParam.
              const sortParamWithoutPrefix = column.replace('user.', '');
              onSortColumnAdd({
                relation: relation || '',
                column: sortParamWithoutPrefix,
                direction
              });
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <div className='d-flex flex-row'>
        {sortColumns.map((sortColumn: Sort, index: number) => {
          const column =
            sortColumn.relation === 'user'
              ? `${sortColumn.relation}.${sortColumn.column}`
              : sortColumn.column;
          return (
            <div
              key={index}
              className='d-flex align-items-center border border-info rounded pl-2 pr-1 mr-1'
              style={{ height: '35px' }}
            >
              <span>
                {column} ({sortColumn.direction})
              </span>
              <Button
                size='sm'
                variant='ghost'
                onClick={() => {
                  onSortColumnRemove(index);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SortingControls;


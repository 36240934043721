import { Filter, Page, Sort } from './types';

export const fetchUsers = async (
  bearerToken: string,
  page: Page,
  sortColumns: Sort[],
  filters: Filter[]
) => {
  const url = new URL(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/unified/users'
  );
  const params = {
    ...page
  };
  // Add parameters to the url.
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key as keyof typeof params].toString())
  );
  // Add sort columns to the url.
  sortColumns.forEach((sort) => {
    const key = `sort[${sort.direction}]`;
    url.searchParams.append(key, sort.column);
  });
  // Add filter columns to the url.
  filters.forEach((filter) => {
    const key = `${filter.column}[${filter.operator}]`;
    url.searchParams.append(key, filter.value);
  });
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      tenant: 'master'
    }
  });
  return response;
};

export const sortableColumns = {
  user: {
    name: {
      label: 'Name',
      type: 'string'
    },
    phone: {
      label: 'Phone',
      type: 'string'
    },
    forecast_provider: {
      label: 'Forecast Provider',
      type: 'string'
    },
    setup_step: {
      label: 'Setup Step',
      type: 'number'
    },
    setup_complete: {
      label: 'Setup Complete',
      type: 'boolean'
    },
    active: {
      label: 'Active',
      type: 'boolean'
    },
    created_at: {
      label: 'Created At',
      type: 'date'
    },
    updated_at: {
      label: 'Updated At',
      type: 'date'
    }
  },
  tenant: {
    name: {
      label: 'Name',
      type: 'string'
    }
  },
  passwordAuth: {
    email: {
      label: 'Email',
      type: 'string'
    },
    email_verified_at: {
      label: 'Email Verified At',
      type: 'date'
    },
    last_login_at: {
      label: 'Last Login At',
      type: 'date'
    },
    login_count: {
      label: 'Login Count',
      type: 'number'
    }
  },
  industryAuth: {
    industry_user_id: {
      label: 'Industry User ID',
      type: 'string'
    },
    email: {
      label: 'Email',
      type: 'string'
    },
    last_login_at: {
      label: 'Last Login At',
      type: 'date'
    },
    login_count: {
      label: 'Login Count',
      type: 'number'
    }
  },
  guestAuth: {
    last_login_at: {
      label: 'Last Login At',
      type: 'date'
    },
    login_count: {
      label: 'Login Count',
      type: 'number'
    }
  },
  apiTokenAuth: {
    last_use_at: {
      label: 'Last Use At',
      type: 'date'
    },
    uses: {
      label: 'Uses',
      type: 'number'
    }
  }
};

export type FilterableColumnTypes = 'string' | 'number' | 'boolean' | 'date';

export type SortableColumnsKey = keyof typeof sortableColumns;

export const filterableColumns = {
  user: {
    access_id: {
      label: 'Access ID',
      type: 'number'
    },
    name: {
      label: 'Name',
      type: 'string'
    },
    phone: {
      label: 'Phone',
      type: 'string'
    },
    forecast_provider: {
      label: 'Forecast Provider',
      type: 'string'
    },
    station_id: {
      label: 'Station ID',
      type: 'string'
    },
    setup_step: {
      label: 'Setup Step',
      type: 'number'
    },
    setup_complete: {
      label: 'Setup Complete',
      type: 'boolean'
    },
    active: {
      label: 'Active',
      type: 'boolean'
    },
    deleted_at: {
      label: 'Deleted At',
      type: 'date'
    },
    created_at: {
      label: 'Created At',
      type: 'date'
    },
    updated_at: {
      label: 'Updated At',
      type: 'date'
    }
  },
  tenant: {
    name: {
      label: 'Name',
      type: 'string'
    },
    default_access_id: {
      label: 'Default Access ID',
      type: 'number'
    },
    deleted_at: {
      label: 'Deleted At',
      type: 'date'
    },
    created_at: {
      label: 'Created At',
      type: 'date'
    },
    updated_at: {
      label: 'Updated At',
      type: 'date'
    }
  },
  passwordAuth: {
    email: {
      label: 'Email',
      type: 'string'
    },
    email_verified_at: {
      label: 'Email Verified At',
      type: 'date'
    },
    last_login_at: {
      label: 'Last Login At',
      type: 'date'
    },
    login_count: {
      label: 'Login Count',
      type: 'number'
    },
    deleted_at: {
      label: 'Deleted At',
      type: 'date'
    }
  },
  industryAuth: {
    industry_user_id: {
      label: 'Industry User ID',
      type: 'string'
    },
    email: {
      label: 'Email',
      type: 'string'
    },
    last_login_at: {
      label: 'Last Login At',
      type: 'date'
    },
    login_count: {
      label: 'Login Count',
      type: 'number'
    },
    deleted_at: {
      label: 'Deleted At',
      type: 'date'
    }
  },
  guestAuth: {
    last_login_at: {
      label: 'Last Login At',
      type: 'date'
    },
    login_count: {
      label: 'Login Count',
      type: 'number'
    },
    deleted_at: {
      label: 'Deleted At',
      type: 'date'
    }
  },
  apiTokenAuth: {
    last_use_at: { label: 'Last Use At', type: 'date' },
    uses: {
      label: 'Uses',
      type: 'number'
    },
    deleted_at: {
      label: 'Deleted At',
      type: 'date'
    }
  }
};

export type FilterableColumnsKey = keyof typeof filterableColumns;

export const filterOperators = [
  {
    label: 'Equals',
    operator: 'eq',
    types: ['string', 'number', 'boolean', 'date']
  },
  {
    label: 'Like',
    operator: 'like',
    types: ['string', 'number', 'date']
  },
  {
    label: 'Greater than',
    operator: 'gt',
    types: ['number', 'date']
  },
  {
    label: 'Greater than or equal',
    operator: 'gte',
    types: ['number', 'date']
  },
  {
    label: 'Less than',
    operator: 'lt',
    types: ['number', 'date']
  },
  {
    label: 'Less than or equal',
    operator: 'lte',
    types: ['number', 'date']
  },
  {
    label: 'In',
    operator: 'in',
    types: ['string', 'number', 'boolean']
  },
  {
    label: 'Not in',
    operator: 'not_ib',
    types: ['string', 'number', 'boolean']
  },
  {
    label: 'Between',
    operator: 'between',
    types: ['number']
  }
];


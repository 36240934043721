import { useCallback, useState } from 'react';
import { Sort } from '../../tools/unified_user_manager/types';

export const useSortColumns = (): [
  Sort[],
  (sort: Sort) => void,
  (index: number) => void
] => {
  const [sortColumns, setSortColumns] = useState<Sort[]>([]);

  const onSortColumnAdd = useCallback(
    (sortColumn: Sort) => {
      const empty = sortColumn.column === '';
      const exists = sortColumns.findIndex(
        (sc) => sc.column === sortColumn.column
      );
      if (empty) return;
      if (exists > -1) {
        const direction = sortColumn.direction;
        // If the column already exists and direction is
        // different, change the direction.
        if (sortColumns[exists].direction !== direction) {
          setSortColumns((prevState) => {
            return prevState.map((sc) => {
              if (sc.column === sortColumn.column) {
                return { ...sc, direction };
              }
              return sc;
            });
          });
        }
        return;
      }
      setSortColumns((prevState) => {
        return [...prevState, sortColumn];
      });
    },
    [sortColumns]
  );

  const onSortColumnRemove = useCallback((index: number) => {
    setSortColumns((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
  }, []);

  return [sortColumns, onSortColumnAdd, onSortColumnRemove];
};


import { makeAutoObservable, runInAction } from 'mobx';
import { User } from './types';

export default class UnifiedUserStore {
  users: User[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setUsers(users: User[]) {
    runInAction(() => {
      this.users = users;
    });
  }

  updateUser(user: User) {
    const index = this.users.findIndex((u) => u.id === user.id);
    if (index !== -1) {
      const newUsers = [...this.users];
      newUsers[index] = user;
      runInAction(() => {
        this.users = newUsers;
      });
    }
  }
}


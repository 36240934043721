import React from 'react';

interface Props {
  type: 'text' | 'number' | 'checkbox' | 'date';
  value: string;
  onChange: (value: string) => void;
}

const ValueInput = ({ type, value, onChange }: Props) => {
  switch (type) {
    case 'text':
      return (
        <input
          type={'text'}
          className='form-control'
          placeholder='Value'
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      );
    case 'number':
      return (
        <input
          type={'number'}
          className='form-control'
          placeholder='Value'
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      );
    case 'checkbox':
      return (
        <input
          type={'checkbox'}
          className='form-control ml-3'
          checked={value === '1'}
          onChange={(e) => {
            onChange(e.target.checked ? '1' : '0');
          }}
          style={{ width: 'auto' }}
        />
      );
    case 'date':
      return (
        <input
          type={'date'}
          className='form-control'
          placeholder='Value'
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      );
    default:
      return (
        <input
          type={'text'}
          className='form-control'
          placeholder='Value'
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      );
  }
};

export default ValueInput;


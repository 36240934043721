import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { Column, useExpanded, usePagination, useTable } from 'react-table';
import {
  UnifiedUserContext,
  UserContext
} from '../../../../store/StoreContexts';
import { User } from '../../../../store/unified_user_store/types';
import { useFilterColumns } from '../../../utils/hooks/useFilterColumns';
import { useSortColumns } from '../../../utils/hooks/useSortColumns';
import { errorToast } from '../../../utils/ToastContainer';
import { ApiUserResponse } from '../types';
import { fetchUsers } from '../utils';
import FilteringControls from './FilteringControls/FilteringControls';
import PaginationControls from './PaginationControls';
import SortingControls from './SortingControls';
import UserAuthDetails from './UserAuthDetails/UserAuthDetails';

const UsersTable = () => {
  const userStore = useContext(UserContext);
  const unifiedUserStore = useContext(UnifiedUserContext);
  const [pageCount, setPageCount] = useState(1);
  const [sortColumns, onSortColumnAdd, onSortColumnRemove] = useSortColumns();
  const [
    filters,
    onFilterAdd,
    onFilterEdit,
    onFilterRemove
  ] = useFilterColumns();

  const users = unifiedUserStore.users;

  const circleStyle = (colour: 'green' | 'red') => {
    return {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      backgroundColor: colour
    };
  };

  const circleContainerStyles = () => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };
  };

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Tenant',
        accessor: 'tenant.name'
      },
      {
        Header: 'Registration Date',
        accessor: 'created_at',
        Cell: ({ value }: { value: string }) =>
          DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss')
      },
      {
        Header: 'Password',
        accessor: 'password_auth',
        Cell: ({ value }: { value: boolean }) =>
          value ? (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('green')} />
            </div>
          ) : (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('red')} />
            </div>
          )
      },
      {
        Header: 'Industry',
        accessor: 'industry_auth',
        Cell: ({ value }: { value: boolean }) =>
          value ? (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('green')} />
            </div>
          ) : (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('red')} />
            </div>
          )
      },
      {
        Header: 'Guest',
        accessor: 'guest_auth',
        Cell: ({ value }: { value: boolean }) =>
          value ? (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('green')} />
            </div>
          ) : (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('red')} />
            </div>
          )
      },
      {
        Header: 'API Token',
        accessor: 'api_token_auth',
        Cell: ({ value }: { value: boolean }) =>
          value ? (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('green')} />
            </div>
          ) : (
            <div style={circleContainerStyles()}>
              <div style={circleStyle('red')} />
            </div>
          )
      },
      {
        Header: 'Details',
        id: 'actions',
        Cell: ({ row }: any) => (
          <Row>
            <Col>
              <Button
                variant='outline-secondary'
                size='sm'
                onClick={() => row.toggleRowExpanded(!row.isExpanded)}
              >
                {row.isExpanded ? 'Hide' : 'Show'}
              </Button>
            </Col>
          </Row>
        )
      }
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns: columns,
      data: users,
      manualPagination: true,
      pageCount,
      initialState: {
        pageIndex: 0,
        pageSize: 20
      }
    },
    useExpanded,
    usePagination
  );

  // Destructure properties and methods from tableInstance.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = tableInstance;

  useEffect(() => {
    const fetchTableData = async () => {
      const response = await fetchUsers(
        userStore.user?.userToken.jwt_bearer || '',
        {
          page_size: pageSize,
          page: pageIndex + 1
        },
        sortColumns,
        filters
      );
      if (!response.ok) {
        if (response.status === 401) {
          await userStore.refresh();
        } else {
          errorToast('Failed to fetch users', 'users-error');
        }
        return;
      }
      const responseData = (await response.json()) as ApiUserResponse;
      unifiedUserStore.setUsers(responseData.data);
      setPageCount(Math.ceil(responseData.total / pageSize));
    };
    fetchTableData();
  }, [
    filters,
    pageIndex,
    pageSize,
    sortColumns,
    unifiedUserStore,
    userStore,
    userStore.user?.userToken.jwt_bearer
  ]);

  return (
    <>
      <div className='mb-3'>
        <div className='mb-2'>
          <Card>
            <Card.Header>
              <Card.Title className='mb-0'>Filtering</Card.Title>
            </Card.Header>
            <Card.Body>
              <FilteringControls
                filters={filters}
                onFilterAdd={onFilterAdd}
                onFilterEdit={onFilterEdit}
                onFilterRemove={onFilterRemove}
              />
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card>
            <Card.Header>
              <Card.Title className='mb-0'>Sorting</Card.Title>
            </Card.Header>
            <Card.Body>
              <SortingControls
                sortColumns={sortColumns}
                onSortColumnAdd={onSortColumnAdd}
                onSortColumnRemove={onSortColumnRemove}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div style={{ overflowX: 'scroll' }}>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      ...column.getHeaderProps().style,
                      textAlign: 'center'
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            ...cell.getCellProps().style,
                            textAlign: 'center',
                            verticalAlign: 'middle'
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td
                        colSpan={visibleColumns.length}
                        className={row.isExpanded ? 'border-top-0 pt-0' : ''}
                      >
                        <UserAuthDetails user={row.original as User} />
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className='d-flex justify-content-between align-items-center mt-2'>
        <PaginationControls
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          onPageSizeChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        />
      </div>
    </>
  );
};

export default observer(UsersTable);


import { useState } from 'react';
import { User } from '../../../../../store/unified_user_store/types';
import UserStore from '../../../../../store/user/UserStore';
import {
  ApiIndustryGenerateResponse,
  ApiUserGenerateResponse
} from '../../types';

export const useLoginAsUser = (userStore: UserStore) => {
  const [loading, setLoading] = useState(false);

  const generateUserBearerToken = async (user: User) => {
    const url = `${process.env.REACT_APP_AUTH_API_URL}api/admin/unified/user/${user.id}/token/jwt/generate`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer || ''}`,
      tenant: user.tenant.name
    };
    const response = await fetch(url, {
      method: 'POST',
      headers
    });
    const data = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        await userStore.refresh();
      }
      return null;
    }
    return data as ApiUserGenerateResponse;
  };

  const generateIndustryToken = async (user: User, bearerToken: string) => {
    const url = `${process.env.REACT_APP_AUTH_API_URL}api/unified/industry/token/jwt/user/generate`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      tenant: user.tenant.name
    };
    const response = await fetch(url, {
      method: 'POST',
      headers
    });
    const data = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        await userStore.refresh();
      }
      return null;
    }
    return data as ApiIndustryGenerateResponse;
  };

  const loginAsUser = async (user: User) => {
    try {
      setLoading(true);
      const data = await generateUserBearerToken(user);
      if (!data) return null;
      const token = await generateIndustryToken(user, data?.jwt_token || '');
      setLoading(false);
      return token;
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  return { loading, loginAsUser };
};


import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  ApiTokenAuth,
  GuestAuth,
  IndustryAuth,
  PasswordAuth
} from '../../../../../store/unified_user_store/types';

interface Props {
  children: ReactNode;
  auth: PasswordAuth | IndustryAuth | GuestAuth | ApiTokenAuth | undefined;
  title: string;
}

const AuthDetailsLayout = ({ children, auth, title }: Props) => {
  return (
    <Row className='border border-info rounded mx-0 mt-2'>
      <Col className='py-3'>
        <Row>
          <Col>
            <h6>{title}</h6>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            {!auth ? (
              <p className='text-muted p-0 m-0'>Not Enabled</p>
            ) : (
              children
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuthDetailsLayout;


import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Filter } from '../../types';
import AddFilter from './AddFilter';
import EditFilter from './EditFilter';

interface Props {
  filters: Filter[];
  onFilterAdd: (filters: Filter) => void;
  onFilterEdit: (filter: Filter, index: number) => void;
  onFilterRemove: (index: number) => void;
}

const FilteringControls = ({
  filters,
  onFilterAdd,
  onFilterEdit,
  onFilterRemove
}: Props) => {
  return (
    <div>
      {filters.map((filter, index) => {
        const { relation, column, operator, value } = filter;
        return (
          <Row key={`${column}.${operator}.${value}`}>
            <Col>
              <div className='d-flex mb-2'>
                <EditFilter
                  initialRelation={relation}
                  initialColumn={column}
                  initialOperator={operator}
                  initialValue={value}
                  filterIndex={index}
                  onFilterEdit={onFilterEdit}
                  onFilterRemove={onFilterRemove}
                />
              </div>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col>
          <div className='d-flex mb-2'>
            <AddFilter onFilterAdd={onFilterAdd} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FilteringControls;


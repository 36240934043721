import { useCallback, useState } from 'react';
import { Filter } from '../../tools/unified_user_manager/types';

export const useFilterColumns = (): [
  Filter[],
  (filter: Filter) => void,
  (filter: Filter, index: number) => void,
  (index: number) => void
] => {
  const [filters, setFilters] = useState<Filter[]>([]);

  const onFilterAdd = useCallback((filter: Filter) => {
    setFilters((prevState) => {
      return [...prevState, filter];
    });
  }, []);

  const onFilterEdit = useCallback((filter: Filter, index: number) => {
    setFilters((prevState) => {
      return prevState.map((f, i) => {
        if (i === index) {
          return filter;
        }
        return f;
      });
    });
  }, []);

  const onFilterRemove = useCallback((index: number) => {
    setFilters((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
  }, []);

  return [filters, onFilterAdd, onFilterEdit, onFilterRemove];
};


import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import {
  Alert,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import {
  MetloadContext,
  MetserviceStationsContext
} from '../../../store/StoreContexts';
import StationsMap from '../../maps/StationsMap';
import CenterSpinner from '../../utils/CenterSpinner';
import LinkMetserviceSiteForm from './LinkMetserviceSiteForm/LinkMetserviceSiteForm';
import MetserviceStationManager from './MetserviceStationManager/MetserviceStationManager';
import MetserviceStationsTable from './MetserviceStationTable/MetserviceStationsTable';

const MetserviceStationsPage = () => {
  const metserviceStore = useContext(MetserviceStationsContext);
  const metloadStore = useContext(MetloadContext);
  const stationStore = metserviceStore.stationStore;
  const regionStore = stationStore.regionStore;

  const mapStations = (metserviceStore.selectedStation?.site
    ? [metserviceStore.selectedStation.site]
    : metserviceStore.metserviceStations
  ).map((ms) => {
    const station = ms.station;
    return {
      Station_ID: station.Station_ID,
      Logger_Name: station.Logger_Name,
      Longitude: Number(station.Longitude),
      Latitude: Number(station.Latitude),
      Logger_Type: 'Metservice'
    };
  });

  useEffect(() => {
    const init = async () => {
      if (metserviceStore.stationStore.stations.length === 0)
        await metserviceStore.stationStore.requestStations();
      await metloadStore.initializeData();
      await regionStore.fetchCountries();
    };
    init();
    // De-select station when the page is un-mounted.
    return () => {
      metserviceStore.deSelectStation();
    };
  }, [metserviceStore, metloadStore, regionStore]);

  useEffect(() => {
    let ignore = false;
    // Fetch metservice stations.
    if (!ignore) {
      metserviceStore
        .loadStations()
        .then(() => metserviceStore.loadAPIStations())
        .finally(() => metserviceStore.getMatchedMetserviceStations());
    }
    return () => {
      ignore = true;
    };
  }, [metserviceStore]);

  return metserviceStore.stationStore.loading ||
    metloadStore.loading ||
    metserviceStore.loadingInitial ? (
    <CenterSpinner />
  ) : (
    <Container>
      <Row className='mt-2'>
        <Col>
          <Row>
            <Col>
              <Card className='my-3 w-100 rounded-0'>
                <Alert className='mb-0' variant='info'>
                  <p className='m-0'>
                    <b>Note:</b> This table only shows MetService stations that
                    we have access to from a{' '}
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://github.com/HortPlus-NZ/admin-tools/blob/master/src/store/metservice_stations/MetserviceUtils.ts#L110'
                    >
                      list of WMO IDs
                    </a>
                    . This list of WMO IDs will need updating as we are given
                    access to more stations.
                  </p>
                </Alert>
                <hr className='m-0' />
                <MetserviceStationsTable />
              </Card>
            </Col>
          </Row>
          {metserviceStore.selectedStation ? (
            metserviceStore.selectedStation.site &&
            metserviceStore.selectedStation.station ? (
              <Row>
                <Col>
                  <MetserviceStationManager />
                </Col>
              </Row>
            ) : (
              <Modal
                show={metserviceStore.selectedStation ? true : false}
                onHide={() => {
                  metserviceStore.deSelectStation();
                }}
              >
                <Modal.Header>Link Site</Modal.Header>
                <Modal.Body>
                  <LinkMetserviceSiteForm />
                </Modal.Body>
              </Modal>
            )
          ) : null}
          <Row>
            <Col>
              {metserviceStore.loadingMetserviceStations ? (
                <div className='text-center'>
                  <Spinner animation='border' variant='primary' />
                </div>
              ) : (
                <StationsMap stations={mapStations} loading={false} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(MetserviceStationsPage);


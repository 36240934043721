import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { User } from '../../../../../store/unified_user_store/types';
import UserEditButton from '../UserEdit/UserEditButton';
import UserLoginButton from '../UserLogin/UserLoginButton';
import AuthDetailsLayout from './AuthDetailsLayout';

interface Props {
  user: User;
}

const UserAuthDetails = ({ user }: Props) => {
  const passwordAuth = user.password_auth;
  const industryAuth = user.industry_auth;
  const guestAuth = user.guest_auth;
  const apiTokenAuth = user.api_token_auth;

  const passwordAuthEmail = passwordAuth?.email;
  const passwordAuthLoginCount = passwordAuth?.login_count;
  const passwordAuthLastLogin = passwordAuth?.last_login_at
    ? DateTime.fromISO(passwordAuth?.last_login_at || '').toFormat(
        'yyyy-MM-dd HH:mm:ss'
      )
    : '-';
  const passwordAuthEmailVerifiedAt = passwordAuth?.email_verified_at
    ? DateTime.fromISO(passwordAuth?.email_verified_at || '').toFormat(
        'yyyy-MM-dd HH:mm:ss'
      )
    : '-';

  const industryUserId = industryAuth?.industry_user_id;
  const industryAuthEmail = industryAuth?.email;
  const industryAuthLoginCount = industryAuth?.login_count;
  const industryAuthLastLogin = industryAuth?.last_login_at
    ? DateTime.fromISO(industryAuth?.last_login_at || '').toFormat(
        'yyyy-MM-dd HH:mm:ss'
      )
    : '-';

  const guestAuthLoginCount = guestAuth?.login_count;
  const guestAuthLastLogin = guestAuth?.last_login_at
    ? DateTime.fromISO(guestAuth?.last_login_at || '').toFormat(
        'yyyy-MM-dd HH:mm:ss'
      )
    : '-';

  const apiTokenAuthUses = apiTokenAuth?.uses;
  const apiTokenAuthLastUse = apiTokenAuth?.last_use_at
    ? DateTime.fromISO(apiTokenAuth?.last_use_at || '').toFormat(
        'yyyy-MM-dd HH:mm:ss'
      )
    : '-';

  const name = user.name;
  const phone = user.phone;
  const forecastProvider = user.forecast_provider;
  const stationId = user.station_id;
  const active = user.active;

  return (
    <div
      className='border-left border-secondary'
      style={{ borderBottomLeftRadius: '10px' }}
    >
      <div className='pl-3 pb-2'>
        <Row className='mt-0'>
          <Col className='ml-2 d-flex flex-row'>
            <div className='mr-4'>
              <h6 className='pt-2'>User Details</h6>
            </div>
            <div>
              <UserEditButton user={user} />
            </div>
          </Col>
        </Row>
        <Row className='mt-2 mb-3'>
          <Col className='ml-2'>
            <Row>
              <Col>
                <b>Name:</b> {name}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Phone:</b> {phone}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Forecast Provider:</b> {forecastProvider}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Station ID:</b> {stationId}
              </Col>
            </Row>
            <Row>
              <Col className='d-flex flex-row'>
                <div>
                  <b className='mr-2'>Active:</b>{' '}
                </div>
                <div
                  className={`border rounded-circle ${
                    active ? 'border-success' : 'border-danger'
                  } px-1`}
                >
                  {active ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      color='green'
                      style={{ width: '15px' }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      color='red'
                      style={{ width: '15px' }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col className='ml-2 d-flex flex-row'>
            <div className='mr-4'>
              <h6 className='pt-2'>Auth Details</h6>
            </div>
            {user.industry_auth !== null ? (
              <div className='mr-2'>
                <UserLoginButton user={user} />
              </div>
            ) : null}
          </Col>
        </Row>
        <AuthDetailsLayout auth={passwordAuth} title={'Password Auth'}>
          <>
            <Row>
              <Col>
                <b>Email:</b> {passwordAuthEmail}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Login Count:</b> {passwordAuthLoginCount}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Last Login:</b> {passwordAuthLastLogin}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Email Verified At:</b> {passwordAuthEmailVerifiedAt}
              </Col>
            </Row>
          </>
        </AuthDetailsLayout>
        <AuthDetailsLayout auth={industryAuth} title={'Industry Auth'}>
          <>
            <Row>
              <Col>
                <b>Industry ID:</b> {industryUserId}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Email:</b> {industryAuthEmail}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Login Count:</b> {industryAuthLoginCount}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Last Login:</b> {industryAuthLastLogin}
              </Col>
            </Row>
          </>
        </AuthDetailsLayout>
        <AuthDetailsLayout auth={guestAuth} title={'Guest Auth'}>
          <>
            <Row>
              <Col>
                <b>Login Count:</b> {guestAuthLoginCount}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Last Login:</b> {guestAuthLastLogin}
              </Col>
            </Row>
          </>
        </AuthDetailsLayout>
        <AuthDetailsLayout auth={apiTokenAuth} title={'API Token Auth'}>
          <>
            <Row>
              <Col>
                <b>Uses:</b> {apiTokenAuthUses}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Last Use:</b> {apiTokenAuthLastUse}
              </Col>
            </Row>
          </>
        </AuthDetailsLayout>
      </div>
    </div>
  );
};

export default UserAuthDetails;

